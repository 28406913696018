import logo from './henriklogo.png';
// import henrik from './forestpic-removedbg.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="spinning henrik logo" />
      </header>
      <div>
        <h1 className="b">
          COMING SOON
        </h1>
        <p>
          See the source code on <a href="https://github.com/henrikvtcodes/comingsoonv1">Github.</a>
        </p>
      </div>
    </div>
  );
}

export default App;